<template>
  <v-card>
    <v-card-title class="primary white--text">
      {{item.title}}
    </v-card-title>
    <v-card-text class="pt-5">
      <v-sheet
        class="overflow-hidden mb-5"
        rounded="lg"
        color="transparent"
        max-height="200"
      >
        <v-img :src='(print_image)? print_image :($store.state.imagePath + item.image_path)'>
          <v-btn
          @click="onButtonClick"
            class="primary"
            icon
            absolute
            top
            right
          >
            <v-icon color="white" small>fas fa-edit</v-icon>
          </v-btn>
          <input
              ref="uploader"
              id="image_path"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            />
        </v-img>
      </v-sheet>
      <v-text-field
        v-model=" item.title"
        label="Nome da gravação"
        rounded
        outlined
        dense
      />
      <!-- <v-textarea
        v-model=" item.description"
        label="descrição"
        rounded
        outlined
        dense
        auto-grow
      /> -->
      <ckeditor  v-model="item.description"
                label="Observações"
                rounded
                dense
                outlined
                auto-grow
              />
      <v-text-field
      class="mt-4"
        v-model=" item.link_path"
        label="Link do Vídeo"
        rounded
        outlined
        dense
      />

      <v-btn
        @click="deleteCard"
          class="red"
          icon
          absolute
          bottom
          dense
          left
        >
          <v-icon color="white" small>fas fa-trash</v-icon>
      </v-btn>
    </v-card-text>
    
    <v-card-actions>
      <v-spacer />
      <v-btn @click="$emit('closeDialog')" outlined color="primary" rounded>
        cancelar
      </v-btn>
      <v-btn rounded color="primary" @click="saveCard">
        salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item: Object,
    list: Array
  },
  data() {
    return {
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Strike', 'Styles', 'Format' ] ]
      },
      print_image:null
    }
  },
  methods: {
    saveCard(){

      const formData = new FormData();
      const siteKeys = Object.keys(this.item);
      const siteValues = Object.values(this.item);
      for (let i = 0; i < siteKeys.length; i++) {
        if (siteValues[i] !== null) {
          if(siteKeys[i] != 'printCardDialog'){
            formData.append(siteKeys[i], siteValues[i]);
          }
        }
      }
      console.log(formData)
      this.$api
      .post(`site/prints/update`,formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        if(this.print_image!=null){
          location.reload()
        }else
          this.$emit('closeDialog')
      })
      .catch(() => {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar Atualizar esta gravação',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false
            })
          }
        })
      });
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
        this.item.image_path = e.target.files[0];
        this.print_image = URL.createObjectURL(e.target.files[0]);
    },
    deleteCard(){
      this.$api
      .post(`site/prints/delete`,{id:this.item.id})
      .then(() => {
          this.removeCard()
      })
      .catch(() => {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar Atualizar esta gravação',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false
            })
          }
        })
      });
    },
    removeCard() {
      let idx = this.list.indexOf(this.item)
      this.list.splice(idx, 1)
    },
  },

}
</script>